.footer-wrapper {
    display: grid !important;
    grid-template-rows: 1vh 24vh;
    grid-template-columns: 46vw 46vw;
    grid-row-gap: 5vh;
    margin-bottom: 2vh;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background: rgba(0, 0, 0, 0);
    z-index: 100;
    background-image: linear-gradient(#ffffff, lightgrey);
}

.dividor {
    width: 92vw;
    height: 1px;
    border: grey solid 1px;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1;
}

.footer-left {
    grid-row-start: 2;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
    
.footer-right {
    grid-row-start: 2;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.footer-wrapper .footer-description p {
    line-height: 150%;
    font-weight: normal;
    text-align: left;
}

.footer-wrapper .footer-socials img {
    width: 2vw;
}

@media screen and (max-width: 800px) {
    
    .footer-wrapper * {
        font-size: 1.4rem;
    }


}

@media screen and (max-width: 500px) {
    .footer-wrapper {
        display: flex;
        flex-direction:column;
        width: 100vw;
        align-items: center;
        justify-content: center;
    } 
    
    .footer-wrapper * {
        font-size: 0.8rem;
        text-align:center;
    }

    .footer-wrapper .footer-main {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 33vw;
        margin-bottom: 3vh;
        margin-top: 3vh;
    }
    
    .footer-wrapper .footer-left {
        width: 30vw;
        align-items:center;  
         
    }

    @keyframes circle-anim{
      0% {
        
      }
      
      90%{
        padding: 0.35em;
        margin: -0.35em; 
      }
      
      100%{
        padding: 0.25em;
        margin: -0.25em; 
      }
    }
}


