.bg-custom {
    height: min-content;
    border-radius: 15px;
}

.bg-custom * {
    background-color: transparent;;
    color: black;
}

.link {
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
}

.navbar-brand {
    margin-right: 0 !important;
    margin-left: 1vw !important;
}

.nav-link p {
    margin: 0;
}
