* {
    overflow-x: hidden;
}

*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0,0.5);
}

