.classes-wrapper {
    height: min-content;
    width: 100%;
    display: grid;
    background-color: white;
    
    --titleverybig: 3.5rem;
    --subtitleverybig: 2.5rem;

    --titlebig: 3rem;
    --subbig: 2rem;

    --titlemed: 2rem;
    --submed: 1.5rem;

    --titlesmall: 1.5rem;
    --subsmall: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.classes-wrapper .title-wrapper {
    margin-top: 20vh;
}

.classes-wrapper .subtitle-wrapper {
    padding-top: 10vh;
    padding-left: 20vw;
    padding-right: 20vw;
    font-size: var(--submed);
}

.classes-wrapper .title {
    font-size: var(--titleverybig);
    text-align: center;
}

.classes {
    width: 80vw;
    padding-top: 20vh;
    height: 100vh;
}

.classes * {
    overflow:hidden;
}
    
.classes .accordion {
}

@media screen and (max-width: 1000px) {
    .classes-wrapper .title {
        font-size: var(--titlemed);
    }
    .subtitle {
        font-size: var(--submed);
    }
    
    .classes-wrapper .subtitle-wrapper {
        text-align: center;
    }

    .classes {
        justify-content:center;
    }

}
