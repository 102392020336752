#body-content {overflow: hidden;}

/* Global styles */

.home-wrapper {
    height: 100%;
    width: 100%;
    display: grid;
    background-color: white;
    
    --titleverybig: 3.5rem;
    --subtitleverybig: 2.5rem;

    --titlebig: 3rem;
    --subbig: 2rem;

    --titlemed: 2rem;
    --submed: 1.5rem;

    --titlesmall: 1.5rem;
    --subsmall: 1rem;
}

.home-wrapper {
    margin-top:20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}


/* Landing styles */

.home-wrapper .title {
    font-size: var(--titleverybig);
    margin-bottom: 3vh;
}
.home-wrapper .subtitle {
    font-size: var(--subbig);
    margin-bottom: 3vh;
}
.home-wrapper .button-wrapper {
    display:flex;
    flex-direction:row;
    width:20vw;
}

.home-wrapper .home-carousel {
    display:flex;
    flex-direction:row;
    justify-content:left;
    align-items:left;
}

.title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    padding-left: 5vw;
    margin-right: 5vw;
}


.carousel {
    width: 45vw;
}

.carousel-img {
    width: 45vw;
    border-radius: 25px;
}

/* picture styles */

.picture-wrapper {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50rem;
    left:0px;
}

.picture-wrapper img {
    object-fit: contain;
    width: 100vw;
}

/* testimonial styles  */

.testimonial-wrapper {
	margin-top:30vh;
	width: 100vw;
	padding-right: 10vw;
	padding-bottom: 10vh;
	display:flex;
	flex-direction:row;
	align-items:right;
	justify-content: right;
}

.test-left {
	width: 30vw;
	padding-top: 10vh;
	padding-bottom: 10vh;
	margin-left: 5vw;
}

.testimonial {
	margin-top: 2vh;
	width: 50vw;
	padding: 1vw;
	margin-left: 1vw;
	margin-right: 1vw;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(10, 0, 0, 0.3) 0px 1px 3px -1px;
	font-size: var(--submed);
	z-index:100000000;
}

.test-title {
    text-align: right;
    font-weight: bolder;
    font-size: var(--titlebig);
}

.pic3 {
	z-index:0;
	height:15vw;
	border-radius:20px;
	transform: rotate(10deg);
}

/* About styles */

.content-cards {
    width: 25vw;
    margin: 1rem;
}

.about-top {
    display: flex;
    flex-direction: row;
    padding-bottom: 10vh;
   	margin-top: 10vh; 
	width: 100vw;
}

.about-wrapper {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-image: linear-gradient(#ffffff, #c2f6ff);
    align-items: center;
	margin-top: 10vh;
}

.about-top * {
    background-color: transparent;
    color: black;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 5vh;
    padding-top: 20vh,
}


.about-left {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 3vh 0vh 5vh 5vh;
    width: 40vw;
    height: max-content;
    margin-left: 10vw;
}

.about-right {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
	padding-top: 5vh;
	padding-bottom: 5vh;
}

.about-right img {
    border-radius: 20px;
    width: 30vw;
	transform: rotate(-10deg);
}

.about-title {
    margin-top: 1vh;
}

.about-content {
    font-size: var(--submed);
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-style: bold;
    text-align: left;
    flex-wrap:  wrap-reverse;
    line-height: 125%;
}

.about-content p {
    line-height: 4.5vh;
}

.about-title * {
    text-align: left;
    font-weight: bolder;
    font-size: var(--titlebig);
}

/* misc. */

.line {
    width: 60%;
    background-color: transparent;
    border: darkslategrey solid 1px;
}


/* Our Approach styles */

.approach-wrapper {
    background-image: linear-gradient(#c2f6ff, #aaffd0, #ffffff);
}


/* Footer Styles */

/* Copyright styles */

.copyright-wrapper {
    background-color: black;
    color: white;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 20rem;
    padding-right: 20rem;
}

.copyright-wrapper * {
    background-color: black;
    color: white;
    font-size: 1rem;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

@media screen and (max-width: 1000px) {
    .home-wrapper .title {
        font-size: var(--titlemed);
        text-align: center; 
    }
    .home-wrapper .subtitle {
        font-size: var(--submed);
        text-align: center; 
    }
    .home-wrapper .button-wrapper {
        flex-direction:column;
        width:70vw;
        margin-bottom:5vh;
   }
    
   .title-wrapper {
        align-items: center;
        justify-content:center;
 
   }
    
   .home-wrapper .home-carousel {
        flex-direction:column;
        align-items:center;
    }
    
    .carousel {
        width: 90vw;
    }
    
    .carousel-img {
        width: 90vw;
    }
}


@media screen and (max-width: 800px) {
    .about-top {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        width: 100vw;
    }

    .cards {
        flex-direction: column;
    }
    
    .content-cards {
        margin: 5vh 5vh 0 0;
        width: 75vw;
    }

    .about-left {
        background-color: transparent;
        display: flex;
        padding: 0;
        margin: 5vw;
        flex-direction: column;
        border-radius: 20px;
        width: 90vw;
        height: max-content;
        margin-bottom: 5vh;
    }

    .about-right {
        width: 100vw;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: max-content;
    }

    .about-right img {
        width: 90vw;
        height: auto;
        margin-left: 5vw;
        margin-right: 5vw;
        display: flex;
    }
	
	.testimonial-wrapper {
		flex-direction: column-reverse;
		align-items:center;
		justify-content:center;
		width: 100vw;
		padding: 0;
	}
	
	.testimonial {
		width: 90vw;
	}

	.test-left {
		padding: 0;
		margin-top: 10vh;
		padding-top: 10vh;
		width: 90vw;
		height: 100vh;
	
		margin: 0;
	}

	.test-title {
		text-align: center;
	}
	
	.pic3 {
		width: 90vw;
		height: auto;
	}
	
}

@media screen and (max-width: 500px) {
    .about-content {
        font-size: var(--submed);
        margin-top: 2vh;
        font-style: bold;
        text-align: left;
        flex-wrap:  wrap-reverse;
        line-height: 125%;
    }
    
    .about-title * {
        color: black;
        text-align: left;
        font-weight: bolder;
        font-size: var(--titlemed);
    }


}
